.paddingAll {
    padding: 10px 10px 10px 10px;
}

.priceTitle {
    font-weight: 600;
}

.priceSubTitle {
    font-size: 14px;
}

.iconStyle {
    color: aliceblue;
}

.iconStyleLight {
    margin-top: 20px !important;
    margin-left: 20px !important;
    color: aliceblue;
}

.iconStyleBlack {
    margin-top: 20px !important;
    margin-left: 20px !important;
}

.gradient {
    background: rgb(40, 34, 70);
    background: linear-gradient(158deg, rgb(40, 34, 70, 1) 0%, rgb(30, 47, 141, 1) 100%);
}

.gradientlight {
    background: rgb(53, 138, 148);
    background: linear-gradient(158deg, rgb(53, 138, 148, 1) 0%, rgb(91, 180, 96, 1) 100%);
}

.bgColor {
    min-height: 100vh;
    background: #efefef;
    background: linear-gradient(158deg, rgb(224, 224, 224) 0%, rrgb(233, 237, 254) 100%);
}

.calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.custom-calendar {
    max-width: 100%;
    width: 100%;
}